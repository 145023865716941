import React from "react"
import { RendererProvider } from "react-fela"
import { createRenderer } from "fela"
import { rehydrate } from "fela-dom"
import config from "!/fela.config.js"

export const wrapRootElement = ({ element }) => {
  const renderer = createRenderer(config)

  rehydrate(renderer)

  return <RendererProvider renderer={renderer}>{element}</RendererProvider>
}
