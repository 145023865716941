const unitPlugin = require("fela-plugin-unit").default
const namedKeysPlugin = require("fela-plugin-named-keys").default
const felaMonolithic = require("fela-monolithic").default

const makeMinMediaQuery = breakpoint => `@media (min-width: ${breakpoint}px)`

const makeMaxMediaQuery = breakpoint =>
  `@media (max-width: ${breakpoint - 1}px)`

const defaultUnit = "px"

const breakpoints = {
  xs: 400,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1800,
}

const namedKeys = {
  xsMin: makeMinMediaQuery(breakpoints.xs),
  smMin: makeMinMediaQuery(breakpoints.sm),
  smMax: makeMaxMediaQuery(breakpoints.sm),
  mdMin: makeMinMediaQuery(breakpoints.md),
  mdMax: makeMaxMediaQuery(breakpoints.md),
  lgMin: makeMinMediaQuery(breakpoints.lg),
  xlMin: makeMinMediaQuery(breakpoints.xl),
}

const isDevMode = process.env.NODE_ENV !== "production"

module.exports = {
  plugins: [unitPlugin(defaultUnit), namedKeysPlugin(namedKeys)],
  enhancers: [
    isDevMode &&
      felaMonolithic({
        prettySelectors: true,
      }),
  ].filter(Boolean),
  devMode: isDevMode,
}
